@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.services-title {
    font-family: "Inter", sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
    padding: 20px;
    background-color: #f5f5f5;
}

.cards-container {
    background-color: #f5f5f5;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin: 20px; */
}

.service-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 200px;
    width: 400px;
    background-color: rgb(79, 80, 100);
    color: white;
}

.service-title {
    font-family: "Inter", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 20px;
}

.service-description {
    font-family: "Inter", sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
}