* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.purchase-main-content {
    border: 2px solid red;
    display: flex;
    height: 100vh;
}

.purchase-text-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid green;
}

.purchase-form-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid black;
}