/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dubai-main-content {
    border: 2px solid red;
    display: flex;
    height: 100vh;
}

.dubai-text-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid green;
}

.dubai-form-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid black;
}

@media screen and (max-width: 900px) {
    .dubai-main-content {
        flex-direction: column;
    }

    .dubai-text-content {
        width: 100%;
    }

    .dubai-form-content {
        width: 100%;
    }
} */
.dubai {
    margin: 4rem 0;
}
