* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main-container {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 80%;
}

.sale-content {
    margin: 20px;
    padding: 20px;
    width: 50%;
    text-align: left;
}

.sale-content h1 {
    font-size: 2rem;
    font-weight: 700;
    margin: 2rem 0;
    color: #bc1823;
}


.sale-content p {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 2rem 0;
    color: #1b282b
}

.sale-content h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 2rem 0;
    color: #bc1823;
}

.form-content {
    width: 50%;
}

.radio-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 20px;
    margin: 20px;
    padding: 10px;
}

.radio-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label {
    background-color: #06884b;
    color: white;
}

label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border: 2px solid #0e0f50;
    margin: 5px;
    width: 150px;
    height: 50px;
    border-radius: 30px;
    font-weight: 700;
}

label:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: all 0.2s linear;
}

.sale-form {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.form-label {
    margin: 10px;
    padding: 10px;
    border: 2px solid #000;
}

.form-submit-button {
    margin: 20px;
    padding: 20px;
    border: 2px solid #000;
    border-radius: 3rem;
}

.form-submit-button:hover {
    background-color: #000;
    color: #fff;
    cursor: pointer;
}

.name-input {
    margin: 20px;
    padding: 20px;
    border: 2px solid #000;
    font-size: large;
    background-color: #f5f5f5;
}

.email-input {
    margin: 20px;
    padding: 20px;
    border: 2px solid #000;
    font-size: large;
    background-color: #f5f5f5;
}

.number-input {
    margin: 20px;
    padding: 20px;
    border: 2px solid #000;
    font-size: large;
    background-color: #f5f5f5;
}

.location-input {
    margin: 20px;
    padding: 20px;
    border: 2px solid #000;
    font-size: large;
    background-color: #f5f5f5;
}

.details-input {
    margin: 20px;
    padding: 20px;
    border: 2px solid #000;
    height: 250px;
    background-color: #f5f5f5;
}

@media screen and (max-width: 900px) {
    .main-container {
        flex-direction: column;
    }

    .radio-options {
        display: block;
    }

    .radio-1-container {
        border-bottom: 2px solid #000;
    }

    .radio-2-container {
        border-bottom: 2px solid #000;
    }

    .radio-1-options {
        margin: 10px;
        font-size: 22px;
    }

    .radio-2-options {
        margin: 10px;
        font-size: 22px;
    }

    .sale-content {
        width: 100%;
    }

    .form-content {
        width: 100%;
    }
}

.about-us {
    text-align: center;
    max-width: 1200px;
    margin: 5rem auto;
    font-size: 1.5rem;
}

.about-us p {
    margin: 2rem 0;
    font-size: 1.3rem;
    font-weight: 500;
    color: #1b282b;
}

.about-us h1 {
    font-size: 3rem;
    font-weight: 900;
    color: #bc1823;
}

