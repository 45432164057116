/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.architecture-main-content {
    border: 2px solid red;
    display: flex;
    height: 100vh;
}

.architecture-text-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid green;
}

.architecture-form-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid black;
} */
.architecture-radio-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}