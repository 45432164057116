* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.contact-main-container {

    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.contact-content-container {
    width: fit-content;
    margin: 0 auto;
    font-size: 30px;
    font-weight: 700;
}

.contact-form-container {

    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    font-size: 1.5rem;
}

.input-name {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #000;
    /* border-radius: 5px; */
    margin: 1rem;
    padding: 20px;
}

.input-number {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #000;
    /* border-radius: 5px; */
    margin: 1rem;
    padding: 20px;
}

.input-email {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #000;
    /* border-radius: 5px; */
    margin: 1rem;
    padding: 20px;
}

.input-message {
    width: 100%;
    height: 300px;
    border: 2px solid #000;
    /* border-radius: 5px; */
    margin: 1rem;
    padding: 42px;

}

input:focus::placeholder {
    color: transparent;
}