@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    text-decoration: none;
}

body {
    height: 100vh;
    text-align: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 100px;
    padding-left: 10rem;
    padding-right: 10rem;
    border-bottom: 2px solid #e1e5eb;
    background-color: #f5f5f5;
}

.nav {
    display: none;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}

.nav-link {
    font-size: 1.3rem;
    color: #000000;
    font-weight: 700;
    transform: color 0.1s;
}

.nav-link:hover {
    color: #2563eb;
}

.contact-button {
    display: none;
    border: none;
    border-radius: 0.375rem;
    background-color: #6366f1;
    padding: 0.5rem 1rem;
    color: white
}

.contact-button:hover {
    background-color: #4f46e5;
}

.logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;

}

.logo-icon {
    width: 2.5rem;
    height: 2.5rem;
}

.name p {
    font-family: 'Figtree', sans-serif;
    font-weight: bolder;
    padding: 0;
}

.property {
    color: #bc1823;
    font-size: 1.5rem;
    margin-bottom: -0.5em;
}

.bhopal {
    color: #4d6e75;
    font-size: 1.4rem;
    margin-top: -0.5em;
    text-align: left;
}

.menu-button {
    display: none;
    border: none;
    border-radius: 0.375rem;
}

.nav-small-screen {
    display: none;
}


@media screen and (min-width: 901px) {
    .nav {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 2rem;
    }

    .contact-button {
        display: block;
    }

    .header-small-screen {
        display: none;

    }
}

@media screen and (max-width: 900px) {
    .logo {
        margin-left: 1rem;
    }

    .menu-button {
        display: block;
    }

    .menu-icon {
        height: 2.5rem;
    }

    .header {
        display: none;
    }

    .header-small-screen {
        padding: 0;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;
    }

    .nav-small-screen-false {
        display: none;
    }

    .nav-small-screen-true {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-height: fit-content;
        display: flex;
    }

    .contact-button {
        display: block;
    }

    .small-nav-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: fit-content;
        margin-right: 1rem;
    }
}