/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.loan-main-content {
    border: 2px solid red;
    display: flex;
    height: 100vh;
}

.loan-text-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid green;
}

.loan-form-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid black;
} */
.loan-radio-options {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    padding: 10px;
}