@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;

}

.main-section {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.content-left {
    text-align: center;
    animation: slideFromLeft 1s ease forwards;
    opacity: 0;
}

@keyframes slideFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.section-title {
    font-family: 'Inter', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    color: #161616;
}

.section-description {
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    color: #525151;
}

.button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.services-button {
    display: block;
    border: none;
    border-radius: 0.375rem;
    background-color: #6366f1;
    padding: 0.5rem 1rem;
    color: white;
    width: 150px;
    text-align: center;
}

.services-button:hover {
    background-color: #4f46e5;
}

.content-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dubai-section {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.image-container-dubai {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 10px;
    border: 0px solid #6366f1;
    border-radius: 0.5rem;
    overflow: hidden;
}

.content-dubai {
    text-align: center;
    animation: slideFromRight 1s ease forwards;
    opacity: 0;
}

.section-title-dubai {
    font-family: 'Inter', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    color: #161616;
}

.section-description-dubai {
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    color: #525151;
}

@keyframes slideFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@media screen and (min-width: 901px) {
    .main-section {
        flex-direction: row;
    }

    .content-left {
        max-width: 60%;
        text-align: left;
    }

    .button-group {
        justify-content: flex-start;
    }

    .dubai-section {
        flex-direction: row;
        max-width: 1200px;
    }

    .content-dubai {
        text-align: left;
        padding-left: 20px;
    }

    .section-description-dubai {
        text-align: left;
    }
}
.footer-container{
    margin-top: 20px;
    margin-bottom: 5px;
    padding-top: 2px;
    padding-bottom: 4px;
}

.footer-copyright{
    margin-bottom: 2px;
}