/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.venture-main-content {
    border: 2px solid red;
    display: flex;
    height: 100vh;
}

.venture-text-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid green;
}

.venture-form-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 2px solid black;
} */

.venture {
    margin-top: 50px;
}